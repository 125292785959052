import { get, post } from './axios'
import router from '@/router/index.js'
// get
// export const get_form = data => get('replies/form', data)
// export const lastPage = data => get('replies/prev', data)

// post
// export const post_doreply = data => post('replies/doreply', data)
export const amazon_order_id = data => post('form_items/amazon_order_id', data) // 验证亚马逊订单号是否有效

export const get_form = data => {
  const path = router.currentRoute.path
  if (path === '/warranty') {
  	return get('simrex/replies/form', data)
  }
  // if (path === '/warranty') {
  //   return get('replies/form', data)
  // }
 //  if (path === '/nc9') {
 //    return get('nc9_replies/form', data)
 //  }
 //  if (path === '/t12') {
 //    return get('t12_replies/form', data)
 //  }
 //  if (path === '/a1') {
	// return get('a1/replies/form', data)
 //  }
 //  if (path === '/buds') {
 //  	return get('buds/replies/form', data)
 //  }
}

export const lastPage = data => {
  const path = router.currentRoute.path
  if (path === '/warranty') {
    return get('simrex/replies/prev', data)
  }
  // if (path === '/warranty') {
  //   return get('replies/prev', data)
  // }
  // if (path === '/nc9') {
  //   return get('nc9_replies/prev', data)
  // }
  // if (path === '/t12') {
  //   return get('t12_replies/prev', data)
  // }
  // if (path === '/a1') {
  //   return get('a1/replies/prev', data)
  // }
  // if (path === '/buds') {
  //   return get('buds/replies/prev', data)
  // }
}

export const post_doreply = data => {
  const path = router.currentRoute.path
  if (path === '/warranty') {
    return post('simrex/replies/doreply', data)
  }
  // if (path === '/warranty') {
  //   return post('replies/doreply', data)
  // }
  // if (path === '/nc9') {
  //   return post('nc9_replies/doreply', data)
  // }
  // if (path === '/t12') {
  //   return post('t12_replies/doreply', data)
  // }
  // if (path === '/a1') {
  //   return post('a1/replies/doreply', data)
  // }
  // if (path === '/buds') {
  //   return post('buds/replies/doreply', data)
  // }
}
