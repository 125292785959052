<template>
<!-- 表单首页 -->
<!-- page-content public-page -->
<div class="templateBox">
	<div class="formBox">
		
		<!-- 第一页显示的表头 --> <!-- 固定表头 -->
		<div v-if="noData || section.is_first" class="first-page-header">
			
			<div class="first-page-header-top">
				<div class="title">{{firstPageTitle}}</div>
				<div class="explain">Warranty Certificate：<span>Your Order Number</span></div>
				<!-- <a href="https://docs.google.com/forms/d/e/1FAIpQLSdEOd16x0WlwRk-qdj49xHPe00MkWnNKLxoDhNwwxlGxivpsA/viewform">
				  <div class="golink"> Click here</div>
				</a> -->
			</div>
			<div class="first-page-header-bottom">
				<div class="calendar icon">
					<img src="@/assets/icon/date_new.png" alt="">
					<div class="explain">
						18 Months Warranty Extension
						<!-- <p>18 Months</p>
						<p>Warranty Extension</p> -->
					</div>
				</div>
				<div class="calendar icon">
					<img src="@/assets/icon/phone_new.png" alt="">
					<div class="explain">
						Priority Service by Email 7×24 Live Chat
						<!-- <p>Priority Service by</p>
						<p>Email 7×24 Live Chat</p> -->
					</div>
				</div>
				<div class="calendar icon">
					<img src="@/assets/icon/support_new.png" alt="">
					<div class="explain">
						Lifetime Customer Support
						<!-- <p>Lifetime</p>
						<p>Customer Support</p> -->
					</div>
				</div>
			</div>
		</div>
				
		<!-- 非第一页显示的表头 --><!-- 动态表头 -->
		
		<div v-if="!section.is_first && section.section_name" class="page-header public-page" :style="{backgroundColor: get_agba(section.bg_color), color: section.font_color}" >
			<!-- <div class="top-line"></div> -->
			<div class="title" v-if="section.section_name" v-html="getLinkSrc(section.section_name)"></div> <!-- 主标题 后台管理系统的表单编辑中填写的标题-->
			<div class="explain" v-if="section.section_explain" v-html="getLinkSrc(section.section_explain)"></div> <!-- 副标题 后台管理系统的表单编辑中填写的副标题-->
			<div v-show="requiredHint()" class="hint"><span style="margin-right: 5px;">*</span>Enter Required</div> <!-- 必填项提示 -->
		</div>
		
		<div :class="['page', !section.section_name ? 'pageTop' : ''] ">
			
			<!-- 题目列表 -->
			<div v-for="(list, index) in form_items" :key="list.form_section_id+'-'+list.id" :class="['page-content', 'public-page', answer.items[index].intercept ? 'intercept' : ''] " >
				<!-- 简答题-列表的标题 -->
				<div class="titleBox" v-if="getLinkSrc(list.item_name) || getLinkSrc(list.item_explain)">
					<!-- <div v-if="getLinkSrc(list.item_name)" class="title" v-html="(list.is_required ? `<span style='font-weight: 900;font-size: 12px;margin-right: 3px;color: rgba(252, 75, 6, 0.979)' >*</span>` : '') + getLinkSrc(list.item_name)"></div> -->
					<div v-if="getLinkSrc(list.item_name)" :class="['title', get_amazon_type(list.item_name) ? 'amazon_titile' : '']" v-html="(get_is_required(list.is_required, answer.items[index].intercept)  ? `<div style='font-weight: 900;margin-right: 3px;color: #333333';line-height: 1; >*</div>` : '') + getLinkSrc(list.item_name)"></div>
					<div v-if="getLinkSrc(list.item_explain)" :class="['explain', get_amazon_type(list.item_name) ? 'amazon_explain' : ''] " v-html="getLinkSrc(list.item_explain)"></div>
				</div>
				<div v-show="list.image_url" class="view-picture">
					<img :src="list.image_url" alt="">
				</div>
				
				<!-- 显示提交提示 -->
				<div class="SubmitToFinish" v-if="go_to_finish(list.item_name)">
					Please click Submit to finish.
				</div>
						  
				<!-- 单选题目 -->
				<div v-if="list.option_id === 3" class="select radio">
					<div v-for="item in list.form_options" :key="item.form_section_id+'-'+item.id" :class="['select-list', hasOptionsImg(list.form_options) ? 'nothing-img' : (item.id === answer.items[index].choice ? 'active-img' : 'have-img') ]"  @click.stop="selectOptions(item, index)" >
						<div v-show="!hasOptionsImg(list.form_options)" :class="['select-picture', 'radio-select-picture', item.id === answer.items[index].choice ? 'active' : '']">
							<img :src="item.image_url" alt="">
							<span v-show="!item.image_url">none img</span>
						</div>
						<div :class="['select-lable', starRatingFun(item.option_name) ? 'is_star_rating_box' : 'no_star_rating_box']">
							<el-radio v-model="answer.items[index].choice" :label="item.id"><span v-html="getStarRating(item.option_name)"></span></el-radio>
						</div>
					</div>
				
					<!-- <div v-if="list.has_other_option" :class="['other', hasOptionsImg(list.form_options) ? 'nothing-img' : 'have-img']"> -->
					<div v-if="list.has_other_option" :class="['other', hasOptionsImg(list.form_options) ? 'nothing-img' : (answer.items[index].choice === 0 ? 'active-img' : 'have-img') ]">
						<el-radio v-model="answer.items[index].choice" :label="0">other<span v-show="hasOptionsImg(list.form_options)">: </span></el-radio>
						<div class="title-row radio-title-row" :style="hasOptionsImg(list.form_options) ? {} : set_radio_img()" @click="choiceOtherRadio(answer.items[index], index)">
							<el-input v-model="answer.items[index].value" type="textarea" placeholder="please enter" autosize  @blur="changeInput"  @focus="changeInput"></el-input>
							<div class="textarea-line"></div>
							<div class="textarea-line-active" style="height: 2px"></div>
						</div>
					</div>
				</div>
		  
				<!-- 多选题目 -->
				<div v-if="list.option_id === 4" class="select check">
					<el-checkbox-group v-model="answer.items[index].choices">
						<div v-for="item in list.form_options" :key="item.form_section_id+'-'+item.id" :class="['select-list', hasOptionsImg(list.form_options) ? 'nothing-img' : (answer.items[index].choices.includes(item.id) ? 'active-img' : 'have-img') ]" @click.stop="selectCheck(item.id, index)" >
							<div v-show="!hasOptionsImg(list.form_options)" :class="['select-picture', 'checkbox-select-picture', answer.items[index].choices.includes(item.id)?'active':'']">
								<img :src="item.image_url" alt="">
								<span v-show="!item.image_url">none img</span>
							</div>
							<div :class="['select-lable', starRatingFun(item.option_name) ? 'is_star_rating_box' : 'no_star_rating_box']">
								<el-checkbox :label="item.id" @click.stop.native><span v-html="getStarRating(item.option_name)"></span></el-checkbox>
							</div>
						</div>
						
						<div v-if="list.has_other_option" :class="['other', hasOptionsImg(list.form_options) ? 'nothing-img' : (answer.items[index].choices.includes(0) ? 'active-img' : 'have-img') ]">
							<el-checkbox :label="0">other<span v-show="hasOptionsImg(list.form_options)">: </span></el-checkbox>
							<div class="title-row checkbox-title-row" :style="hasOptionsImg(list.form_options) ? {} : set_checkbox_img()" @click="choiceOtherCheckbox(answer.items[index], index)">
								<el-input v-model="answer.items[index].value" type="textarea" placeholder="please enter" autosize @blur="changeInput" @focus="changeInput"></el-input>
								<div class="textarea-line"></div>
								<div class="textarea-line-active" style="height: 2px"></div>
						 </div>
					  </div>
					</el-checkbox-group>
				</div>
		  
				<!-- 下拉题目 -->
				<div v-if="list.option_id === 5" class="select pull drop_down " style="margin-top:20px">
					<el-select v-model="answer.items[index].choice" placeholder="Please select products">
						<el-option v-for="item in list.form_options" :key="item.id" :label="item.option_name" :value="item.id"></el-option>
					</el-select>
				</div>
		  
				<!-- 简短回答 -->
				<div v-if="list.option_id === 1" class="select short" style="margin-top:20px">
					<div class="title-row">
						
						<!-- 跳转亚马逊 -->
						<div class="amazonBox" v-if="get_amazon_type(list.item_name)">
							<div class="amazonImgBox" @click="go_to_amazon()">
								<img src="../assets/icon/simple-icons_amazon.png"/>
								<div class="right_box">
									<div class="star_rating">☆☆☆☆☆</div>
									<el-button type="warning" class="goToAmazonBtn" :loading="backLoading" @click.native="go_to_amazon()" >Tap stars to rate it</el-button>
								</div>
							</div>
							
							<div class="clickAmazon">Or click Amazon App: </div>
							<div class="FindWriteBox">
								<div class="FindThis">Find this order</div>
								<div class="rightArrow">→</div>
								<el-button type="warning" class="WriteProductreview" :loading="backLoading" @click.native="go_to_amazon()" >Write a Product review</el-button>
								<!-- <div class="WriteProductreview">Write a Product review</div> -->
							</div>
							
							<div class="After">If you have posted words reviews, paste the title on the following line:</div>
						</div>
						<!-- blur focus控制蓝色border -->
						
						<div class="clickAmazonInput" style="display: flex;" v-if="get_amazon_type(list.item_name)">
							<!-- <span class="YourReview">Your review headline:</span> -->
							<div class="YourReview_elInput">
								<el-input v-model="answer.items[index].value" type="textarea" placeholder="Please paste the title" autosize @blur="changeInput" @focus="changeInput"></el-input>
								<div class="textarea-line"></div>
								<div class="textarea-line-active" style="height: 2px"></div>
							</div>
						</div>
						
						<!-- 正常其他情况 -->
						<el-input v-if="!get_amazon_type(list.item_name)" v-model="answer.items[index].value" type="textarea" placeholder="Your answer" autosize @blur="changeInput" @focus="changeInput"></el-input>
						<div v-if="!get_amazon_type(list.item_name)" class="textarea-line"></div>
						<div v-if="!get_amazon_type(list.item_name)" class="textarea-line-active" style="height: 2px"></div>
					</div>
					<!-- www.amazon.com/review/create-review -->
				</div>
		  
				<!-- 必填提示 -->
				<div v-show="answer.items[index].intercept" class="error">
					<!-- <i class="el-icon-warning"></i> -->
					<span>{{ answer.items[index].reminder }}</span>
				</div>
				<!-- <div
					v-show="isShowEmpty(list, answer.items[index])"
					class="empty"
					@click="emptyAnswer(answer.items[index])"
				>Clear the selection</div> -->
			</div>
		</div>
	  
		<!-- 底部按钮 -->
      <div v-if="section.id" class="submit">
			<Debounce :is-debounce="3" :time="2000">
				<el-button v-show="!section.is_first" type="warning" class="BackBtn" :loading="backLoading" @click.native="lastPage" >Back</el-button>
			</Debounce>
			
			<Debounce :is-debounce="3" :time="2000">
				<el-button v-show="!section.is_submit"  type="warning" class="NextBtn" :loading="nextLoading" @click.native="submitAnswer" >Next</el-button>
			</Debounce>
		  
			<Debounce :is-debounce="3" :time="2000">
				<el-button v-show="section.is_submit" type="warning" class="submitBtn" :loading="nextLoading" @click.native="submitAnswer" >Submit</el-button>
			</Debounce>
      </div>
	  
    </div>
  </div>
</template>

<script>
import { get_form, post_doreply, lastPage, amazon_order_id } from '@/plugins/api.js'
import Debounce from '@/plugins/debounce.js'
// select radio
export default {
	components: {
		Debounce
	},
	data() {
		return {
			noData: false,
			section: {},
			form_items: [],
			reply: {},
			answer: {
				id: 0,
				section_id: 0,
				items: []
			},
			backLoading: false,
			nextLoading: false,
			firstPageTitle: 'Activate Your Extended Warranty!' //默认不可填表单的看到
		}
	},
	
	// 监听
	watch: {
		"answer": {
			handler: function (newValue, oldValue) {
				this.selectionItems()
			},
			deep: true
		},
	},
  
	created() {
		this.getForm()
		console.log(process.env.VUE_APP_API_URL)
	},
  
	mounted() {
		
	},
  
  methods: {
		set_radio_img() {
			let radioHeight = 'auto'
			this.$nextTick(() => {
				// 获取radio-select-picture的高度
				let radioSelectPicture = document.querySelector('.radio-select-picture');
				radioHeight = radioSelectPicture.clientHeight;
				if(radioHeight){
			  		radioHeight = radioHeight + 'px'
				}
			});
		   return {
				// height: radioHeight,
		   };
		},
		
		set_checkbox_img() {
			let checkboxHeight = 'auto'
			this.$nextTick(() => {
				// 获取radio-select-picture的高度
				let checkboxSelectPicture = document.querySelector('.checkbox-select-picture');
				checkboxHeight = checkboxSelectPicture.clientHeight;
				if(checkboxHeight){
			  		checkboxHeight = checkboxHeight + 'px'
				}
			});
		   return {
				// height: checkboxHeight,
		   };
		},
		
		go_to_amazon(){
			window.open('http://www.amazon.com/review/create-review', '_blank')
		},
		
		get_is_required(required, intercept) {
			if(required == 1 && intercept){
				return true
			}else{
				return false
			}
		},
		
		get_agba(color){
			if(color){
				function hexToRGBA(hex, alpha) {
				  var r = parseInt(hex.slice(1, 3), 16);
				  var g = parseInt(hex.slice(3, 5), 16);
				  var b = parseInt(hex.slice(5, 7), 16);
				  return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
				}
				// 用法示例
				var rgbaColor = hexToRGBA(color, 0.3); // 转换为带有指定透明度的RGBA表示
				return rgbaColor
			}else{
				return "#FFFFFF"
			}
		},
	  
		go_to_finish(text){
	  		if(text.includes("done")){
	  			return true
	  		}else{
	  			return false
	  		}
		},
	  
		get_amazon_type(text){
			if(text.includes('light up')){
				return true
			}else{
				return false
			}
		},
	  
		selectionItems(){
			let that = this
			Object.keys(this.answer).forEach(item => {
				if(item.option_id == 3 || item.option_id == 4){
					// console.log(that.answer.items)
					// this.answer.items[index].value
				}
			})
		},
	  
	  
    // 获取节的数据
    async getForm() {
      let id = localStorage.getItem('reply_id') || ''
      let data = await get_form({ id })
		console.log(data)
      if (!data) {
			// 不可填表单的看到
			this.firstPageTitle = 'Your Extended Warranty is Activated Successfully!'
			return this.noData = true
      }
		// 给他们可填表单的看到
		this.firstPageTitle = 'Activate Your Extended Warranty!'
      let { section, reply } = data
      localStorage.setItem('reply_id', reply.id)
      this.section = section
      this.reply = reply
      let { form_items } = section
      this.form_items = form_items
      // 初始化答案结构
      let items = []
      form_items.forEach(e => {
        items.push(
          {
            id: e.id,
            value: '',
            choice: '',
            choices: [],
            verify_id: e.verify_id,
            option_id: e.option_id,
            is_required: e.is_required,
            intercept: false,
            // 提示语句
            reminder: 'Enter Required'
          }
        )
      })
      this.answer = {
        id: reply.id,
        section_id: section.id,
        items
      }
		this.scrollToTop();
    },
	 
    // 网站提取变成超链接
    getLinkSrc: function(content) {
      if (!content) {
        return ''
      }
      let urlPattern = /(https?:\/\/|www\.)[a-zA-Z_0-9\-@]+(\.\w[a-zA-Z_0-9\-:]+)+(\/[\(\)~#&\-=?\+\%/\.\w]+)?/g
      let emailPattern = /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/g
	  
	  // html自动换行
      content = content.replace(/\n/g, '<br/>')
	  
	  // 清除多余空格（英文用这个会导致单词内换行，虽然美观，但是改变了单词的意思了，中文还好）
      // content = content.replace(/\s/g, '&nbsp;')
	  
	  // 转化网站地址
      content = content.replace(urlPattern, function(match) {
        var href = match
        if (match.indexOf('http') === -1) {
          href = 'http://' + match
        }
        return '<a href="' + href + '" target="_blank" style="color:blue">' + match + '</a>'
      })
	  // 转化邮箱
      content = content.replace(emailPattern, function(match) {
        return '<a href="mailto://' + match + '" style="color:blue">' + match + '</a>'
      })
      return content
    },
	 
    // 选择options
    selectOptions(item, index) {
      this.answer.items[index].choice = item.id
    },
	 
    // 点击图片操作多选
    selectCheck(id, index) {
      const choices = this.answer.items[index].choices
      for (let i = 0; i < choices.length; i++) {
        if (choices[i] === id) {
          choices.splice(i, 1)
          return
        }
      }
      choices.push(id)
    },
	 
	 // 单选 点击其他区域默认选中
	 choiceOtherRadio(obj, index){
		this.answer.items[index].choice = 0
		console.log(obj)
		const choices = this.answer.items[index].choices
		console.log(choices)
	 },
	 
	 // 多选 点击其他区域默认选中
	 choiceOtherCheckbox(obj, index){
	 	const choices = this.answer.items[index].choices
	 	console.log(choices)
		if(choices.includes(0)){
			const zeroIndex = choices.indexOf(0);
			choices.splice(zeroIndex, 1);
		}else{
			choices.push(0)
		}
	 },
	 
    changeInput(e, num) {
      e.target.parentNode.parentNode.classList.toggle('active')
    },
	 
    hasOptionsImg(options) {
      return options.every(e => {
        return !e.image_url
      })
    },
	 
	 starRatingFun(str){
		const pattern = /[\u2605\u2606]/;
		if(pattern.test(str)){
			return true
		}else{
			return false
		}
	 },
	 
	 getStarRating(str){
		 // 定义正则表达式,匹配 ★ 字符
		 const pattern = /(\u2605+)|(\u2606+)/g;
		 let result;
		 if(pattern.test(str)){
			// 使用 replace 方法替换匹配到的字符
			function getStr(){
				// 使用 replace 方法替换匹配到的字符
				  return str.replace(pattern, (match) => {
				    // 判断匹配到的字符是 ★ 还是 ☆
				    if (match.includes('\u2605') || match.includes('\u2606')) {
				      return `<span class="is_star_rating">${match}</span>`;
				    }
				  });
			}
			result = getStr()
		 }else{
			result = str
		 }
		 return result
	 },
	 
    verifyAmazonOrderId(id) {
      amazon_order_id({ amazon_order_id: id }).then(() => {
        return true
      }).catch(() => {
        return false
      })
    },
	 
    submitAnswer() {
      let flag = true
      const { items } = this.answer
      this.nextLoading = true
      items.forEach(e => {
        e.intercept = false
        if ((e.option_id === 3 || e.option_id === 5) && e.is_required === 1) {
          if (!e.value.trim() && !e.choice) {
            e.intercept = true
            flag = false
            this.nextLoading = false
          }
        }
		
        if (e.option_id === 4 && e.is_required === 1) {
          if (!e.choices.length) {
            e.intercept = true
            flag = false
            this.nextLoading = false
          }
          e.choices.forEach(v => {
            if (v === 0 && !e.value.trim()) {
              e.intercept = true
              flag = false
              this.nextLoading = false
            }
          })
        }
		
        if (e.option_id === 1) {
          if (e.is_required === 1 && !e.value.trim()) {
            e.intercept = true
            flag = false
            this.nextLoading = false
          } else {
				 // 校验订单号格式
            // if (e.verify_id === 1 && e.is_required === 1) {
            //   if (!/^[0-9]{3}-[0-9]{7}-[0-9]{7}$/.test(e.value)) {
            //     e.intercept = true
            //     flag = false
            //     e.reminder = 'The order form of Amazon is wrong'
            //   }
            // }
            if (e.verify_id === 2 && !/\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/.test(e.value) && e.is_required === 1) {
              e.intercept = true
              flag = false
              e.reminder = 'Email format error'
            }
          }
        }
		
      })
      if (flag) {
        post_doreply(this.answer).then((res) => {
          this.nextLoading = false
          if (res.section) {
            this.getForm()
          } else {
            const path = this.$route.path
            let query
            switch (path) {
					case '/warranty':
						query = '1'
						break
					// case '/nc9':
					// 	query = '2'
					// 	break
					// case '/t12':
					// 	query = '3'
					// 	break
					// case '/a1':
					// 	query = '4'
					// 	break
					// case '/buds':
					// 	query = '5'
					// 	break
            }
            this.$router.push(`/finish?f=${query}`)
				this.scrollToTop();
          }
        }).catch((err) => {
			this.nextLoading = false
			console.log(err)
          this.$notify.error({
            title: 'error',
            message: 'Network request error'
          })
        })
      } else {
        this.$notify.error({
          title: 'error',
          message: 'Please select or fill in the required questions'
        })
        this.nextLoading = false
      }
    },
	 
    // 如果有必填的题目返回true，header出现必填提示
    requiredHint() {
		let is_required = false
		let intercept = false
		this.answer.items.some(item => {
			if(item.intercept){
				intercept = true
			}
		})
		 
      this.form_items.some(e => {
			if(e.is_required){
				is_required = true
			}
      })
		
		if(intercept && is_required){
			return true
		}else{
			return false
		}
		
		// return this.form_items.some(e => {
		//   return e.is_required
		// })
    },
	 
    // 判断是否显示清空按钮
    isShowEmpty(list, answer) {
      if (!list.is_required && list.option_id !== 1) {
        if (list.form_options.length) {
          if (!(answer.choice === '') || answer.choices.length) {
            return true
          }
        }
      }
    },
	 
    // 清空已填答案
    emptyAnswer(answer) {
      answer.choice = ''
      answer.choices = []
    },
	 
	 // 回到顶部
	 scrollToTop(){
		 // scrollIntoView 可以使元素滚动到可见，
		 // 注意我们使用了 passive true，以优化性能并禁用滚动阻止
		 window.scrollTo(0, 0, { behavior: 'smooth' });
	 },
	 
    // 上一节
    lastPage() {
      this.backLoading = true
      let page = {
        id: this.reply.id,
        section_id: this.section.id
      }
      lastPage(page).then(res => {
			console.log(res)
        let { section, reply } = res
		
        let { form_items } = section
        this.section = section
        this.form_items = form_items
        let items = []
        form_items.forEach((e, i) => {
          var tmp = {
            id: e.id,
            value: undefined,
            choice: undefined,
            choices: undefined,
            option_id: e.option_id,
            is_required: e.is_required,
            intercept: false,
            reminder: 'Required',
			verify_id: e.verify_id
          }
          reply.forEach((v, index) => {
            if (v.form_item_id === e.id) {
              tmp = {
                id: v.form_item_id,
                value: v.input_value,
                choice: v.choice_value == 0 ? '' : v.choice_value,
                choices: v.choices_value,
                option_id: v.option_id,
                is_required: v.is_required,
                intercept: false,
                reminder: 'Required',
					 verify_id: v.verify_id
              }
            }
          })
          items.push(tmp)
        })
        this.answer = {
          id: this.reply.id,
          section_id: section.id,
          items
        }
        this.backLoading = false
			this.scrollToTop();
      }).catch(e => {
        this.backLoading = false
        this.$notify.error({
          title: 'error',
          message: 'Network request error'
        })
      })
    }
  }
}
</script>

<style lang='less' scoped>
.templateBox {
	width: 100%;
	min-height: 100vh;
	background: #E2F3FB;
	overflow: hidden;
	padding: 0px 0px;
	box-sizing: border-box;
	background-size: 100% 100%; 
	background-repeat: no-repeat;
	@media (min-width: 769px) {
		padding: 15px 0px 15px 0px;
		background: #333333;
	}
  
	@media (max-width: 768px) {
		padding: 5px 5px 20px 5px;
		background-image: url('../assets/icon/bg.png');
	}
	
	/* 第一节标题 */
	.first-page-header {
		width: 100%;
		max-width: 772px;
		margin: auto;
		margin-bottom: 12px;
		border-radius: 8px;
		overflow: hidden;
		box-sizing: border-box;
		background-size: 100% 100%; 
		background-repeat: no-repeat;
		background-color: rgba(0, 0, 0, 0);
	  
		@media (min-width: 768px) {
			padding: 40px 20px;
			/* background-image: url('../assets/icon/banner-pc.jpg'); */
		}
	  
		@media (max-width: 768px) {
			padding: 30px 15px;
			/* background-image: url('../assets/icon/banner-web.jpg'); */
		}
	  
		@media (max-width: 500px) {
			margin-bottom: 7px;
		}
	  
	  .first-page-header-top {
			overflow: hidden;
			text-align: center;
			color: #fff;
			@media (max-width: 768px) {
				padding: 0px 0px;
			}
			.title {
				font-size: 40px;
				font-family: bold;
				line-height: 1.5;
				@media (max-width: 1200px) and (min-width: 768px){
					font-size: 32px;
				}
				@media (max-width: 768px) and (min-width: 501px){
					font-size: 32px;
				}
				@media (max-width: 500px) {
					font-size: 32px;
					max-width: 350px;
					margin: auto;
				}
			}
			
			.explain {
				font-size: 16px;
				margin-top: 20px;
				color: #DDDDDD;
				span{
					color: #ffffff;
					font-weight: 500;
				}
				@media (max-width: 500px) {
					margin-top: 20px;
				  font-size: 14px;
				}
			}
			
			.golink {
				padding: 5px 30px;
				color: #00A2E9;
				cursor: pointer;
				font-size: 18px;
				margin-bottom: 10px;
				border-radius: 15px;
				display: inline-block;
				background-color: #fff;
				@media (max-width: 500px) {
				  padding: 3px 15px;
				  font-size: 14px;
				}
			}
		}
	  
		.first-page-header-bottom {
			max-width: 772px;
			margin: auto;
			margin-top: 30px;
			display: flex;
			padding: 10px 70px;
			justify-content: space-between;
			text-align: center;
			color: #ffffff;
			font-size: 14px;
			@media (max-width: 500px) {
				font-size: 11px;
				padding: 0px 0px;
			}
			.icon {
				img {
					height: 38px;
					@media (max-width: 500px) {
						height: 30px;
					}
				}
				.explain {
					margin-top: 5px;
					line-height: 1.5;
					max-width: 140px;
					@media (max-width: 500px) and (min-width: 376px){
						max-width: 90px;
						display: flex;
					}
					@media (max-width: 375px) {
						max-width: 80px;
						display: flex;
					}
				}
			}
	  }
	}
	
	/* 动态标题 */
	.page-header {
		position: relative;
		border-top:none;
		padding: 20px 20px;
		color: #fff;
		max-width: 772px;
		margin: auto;
		margin-bottom: 12px;
		border-radius: 8px;
		text-align: left;
		@media (max-width: 1024px)  and (min-width: 769px){
			margin-top: 0px;
		}
		@media (max-width: 768px) {
			padding: 15px 10px;
		}
		@media (max-width: 500px) {
			margin-bottom: 7px;
		}
		.top-line {
			width: 100%;
			position: absolute;
			height: 10px;
			background-color: #4285f4;
			top: 0;
			left: 0;
		}
	  .title {
			font-size: 22px;
			width: 100%;
			word-wrap: break-word;
			word-break: keep-all;
			@media (max-width: 768px) and (min-width: 501px){
			  font-size: 22px;
			}
			@media (max-width: 500px) {
			  font-size: 22px;
			}
	  }
		.explain {
			font-size: 16px;
			margin-top: 12px;
			word-wrap: break-word;
			word-break: keep-all;
			@media (max-width: 768px) and (min-width: 501px){
			  font-size: 16px;
			}
			@media (max-width: 500px) {
			  font-size: 16px;
			}
		}
		.hint {
			font-size: 14px;
			margin-top: 12px;
			color: red;
			span {
				font-weight: 900;
			}
		}
	}
  
	.formBox {
		width: 100%;
		text-align: center;
		.pageTop{
			@media (min-width: 769px) {
				padding-top: 0px;
			}
		}
		.page {
			width: 100%;
			max-width: 772px;
			margin: 0 auto;
			box-sizing: border-box;
			text-align: left;
			
      .page-content {
			border-radius: 26px;
			box-sizing: border-box;
			padding: 20px 20px 40px 20px;
			background-color: #E5E5E5;
			@media (max-width: 500px) {
				padding: 10px 10px 30px 10px;
			}
			.titleBox{
				border-radius: 5px;
				box-sizing: border-box;
				padding: 7px 0px;
				/* background-color: #429EC9; */
			}
			.SubmitToFinish{
				color: #000000;
				font-size: 18px;
				font-weight: 600;
				width: 100%;
				text-align: center;
				margin-top: 35px;
				margin-bottom: 25px;
			}
			.title {
				line-height: 1.5;
				color: #000000;
				font-size: 18px;
				font-weight: 600;
				display: flex;
				word-wrap: break-word;
				word-break: keep-all;
				span {
					font-weight: 900;
					font-size: 20px;
					margin-left: 3px;
					color: rgba(252, 75, 6, 0.979);
				}
			}
			.amazon_titile{
				width: 100%;
				justify-content: center;
			}
			.explain {
				font-size: 16px;
				color: #000000;
				margin-top: 13px;
				word-wrap: break-word;
				word-break: keep-all;
			}
			.amazon_explain{
				width: 100%;
				justify-content: center;
			}
			@media (max-width: 600px) {
			  .amazon_titile{
			  	width: 100%;
			  	justify-content: flex-start;
			  }
			  .amazon_explain{
			  	width: 100%;
			  	justify-content: flex-start;
			  }
			}
			.view-picture {
				width: 100%;
				margin-top: 20px;
				img {
					width: 100%;
				}
			}
			.indent20{
				padding-left: 20px;
				@media (max-width: 500px) {
					padding-left: 10px;
				}
			}
			.drop_down{
				.el-select {
				   width: 100%;
				}
			}
			.select {
				width: 100%;
				box-sizing: border-box;
				display: flex;
				margin-top: 5px;
				flex-wrap: wrap;
				@media (max-width: 600px) {
				  justify-content: space-between;
				}
          .select-list {
				width: calc(50% - 5px);
            cursor: pointer;
            margin-top: 15px;
				box-sizing: border-box;
				padding: 5px;
				border-radius: 5px;
				text-align: center;
            .select-picture {
              width: 100%;
              height: 205px;
              padding: 15px 5px;
              // border-radius: 4px;
              text-align: center;
              line-height: 205px;
              font-size: 14px;
              box-sizing: border-box;
              // box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149);
              -webkit-user-select: none;
              cursor: default;
              pointer-events: none;
              @media (max-width: 500px) {
                /* height: 100px; */
                line-height: 100px;
              }
              img {
                max-height: 100%;
                max-width: 100%;
                margin: 0 auto;
                display: block;
              }
            }
            .active {
              border: 0px solid #4285f4;
            }
            /deep/.select-lable {
              margin-top: 10px;
              .el-radio{
                width: 100%;
					 display: flex;
					 align-items: center;
					 justify-content: flex-start;
                .el-radio__label{
						white-space: break-spaces;
						text-align: left;
						line-height: 1.3;
						word-wrap: break-word;
						word-break: keep-all;
						vertical-align: top;
						display: inline-block;
					}
					.nothing-img{
						/deep/ .el-radio__label{
							width: 80%;
						}	
					}
              }
            }
          }
          .other {
				width: calc(50% - 5px);
            margin-top: 15px;
            @media (max-width: 500px) {
              /* width: 100%; */
            }
          }
          .title-row {
            width: 100%;
            position: relative;
            @media (max-width: 500px) {
              width: 100%;
            }
            ::v-deep .el-textarea__inner {
              width: 100%;
              overflow-y: hidden;
              color: #202124;
              background-color: transparent;
              outline: none;
              border: none;
              padding: unset;
              font-size: 14px;
              font-family: Aileron;
              &:focus {
                border: none;
              }
            }
            .textarea-line {
              width: 100%;
              position: absolute;
              height: 1px;
              background-color: rgba(0,0,0,0.12);
              left: 0;
            }
            .textarea-line-active {
              position: absolute;
              left: 0;
              width: 100%;
              background-color: #4285f4;
              transform-origin: 50% center;
              -webkit-transform: scaleX(0);
              transform: scaleX(0);
              transition: all 0.3s;
            }
          }
				.amazonBox{
					width: 100%;
					text-align: center;
					margin-top: 10px;
					margin-bottom: 20px;
					.amazonImgBox{
						width: auto;
						max-width: 400px;
						margin: auto;
						border-radius: 26px;
						box-sizing: border-box;
						padding: 12px 15px 15px 15px;
						display: flex;
						justify-content: center;
						align-items: center;
						position: relative;
						background-color: #ffffff;
						/* box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149); */
						
						img{
							width: 100%;
							max-width: 90px;
							@media (max-width: 500px) {
								max-width: 80px;
							}
						}
						.right_box{
							margin-left: 12%;
							box-sizing: border-box;
						}
						.star_rating{
							font-size: 25px;
							line-height: 1;
						}
						.goToAmazonBtn{
							margin-top: 3px;
							padding: 10px 15px;
							box-sizing: border-box;
							color: #000000;
							font-size: 14px;
							border: #202124;
							border-radius: 4px;
							background-color: #f5f5f5;
							box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149);
						}
					}
					.clickAmazon{
						margin-top: 45px;
						font-size: 18px;
						font-weight: 600;
						color: #000000;
						line-height: 1.5;
					}
					.FindWriteBox{
						width: auto;
						max-width: 400px;
						margin: auto;
						margin-top: 10px;
						border-radius: 26px;
						box-sizing: border-box;
					  	padding: 20px 15px;
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: #ffffff;
						/* box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149); */
						.FindThis{
							padding: 8px 8px;
							color: #000000;
							font-size: 14px;
							border: #202124;
							border-radius: 4px;
							display: flex;
							align-items: center;
							line-height: 1;
							border: 1px solid #000000;
						}
						.rightArrow{
							padding: 8px 13px 6px 13px;
							color: #000000;
							font-size: 14px;
							border: #202124;
							border-radius: 4px;
							display: flex;
							align-items: center;
							line-height: 1;
						}
						.WriteProductreview{
							/* padding: 6px 8px;
							color: #000000;
							font-size: 14px;
							display: flex;
							align-items: center;
							border: #202124;
							line-height: 1;
							border-radius: 4px;
							border: 1px solid #000000; */
							
							padding: 8px 8px;
							box-sizing: border-box;
							color: #000000;
							font-size: 14px;
							border: #202124;
							border-radius: 4px;
							background-color: #f5f5f5;
							box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149);
						}
					}
					.After{
						margin-top: 25px;
						margin-bottom: 30px;
						line-height: 1.5;
						font-size: 18px;
						color: #000000;
						font-weight: 600;
					}
					@media (max-width: 600px) {
						.amazonImgBox,
						.FindWriteBox{
							max-width: 500%;
						}
						.FindWriteBox{
							justify-content: center;
					  	   padding: 20px 15px;
						}
						.FindThis{
							padding: 5px 3px;
						}
						.rightArrow{
							padding: 5px 13px;
						}
						.WriteProductreview{
							padding: 5px 3px;
						}
						.clickAmazon,
						.After{
							text-align: left;
						}
					}
				}
				.clickAmazonInput{
					display: flex;
					width: 100%;
					max-width: 500px;
					margin: auto;
					.YourReview{
						margin-right: 8px;
						width: 155px;
						@media (min-width: 500px) {
							display: block;
							min-width: 155px;
						}
						@media (max-width: 500px) {
							display: none;
						}
					}
					
					.YourReview_elInput{
						width: 100%;
						position: relative;
						.textarea-line {
						  left: 0;
						  width: 100%;
						}
					}
					::v-deep .el-textarea__inner {
					  overflow-y: hidden;
					  color: #202124;
					  background-color: transparent;
					  outline: none;
					  border: none;
					  padding: unset;
					  font-size: 14px;
					  font-family: Aileron;
					  &:focus {
					    border: none;
					  }
					}
				}
          .active {
            .textarea-line-active {
              -webkit-transform: scaleX(1);
              transform: scaleX(1);
            }
          }
			 .other.have-img,
			 .other.active-img{
					display: flex;
					flex-direction: column-reverse;
					/deep/ .el-radio {
					   /* margin-left: 7.5px; */
					}
					.title-row{
						height: 100%;
					}
					.el-checkbox,	
					.el-radio {
						margin-bottom: 6px;
					   margin-top: 10px;
						justify-content: center;
						align-items: center;
					}
					.el-textarea {
						width: 100%;
						height: 100%;
						padding: 15px 10px;
						/deep/ textarea{
							height: 100% !important;
						}
					}
					
					/deep/ .el-radio {
					   width: 100%;
					   display: flex;
					   align-items: center;
					   justify-content: center;
					}
			 }
			 .have-img,
			 .active-img{
					@media (min-width: 784px) {
						margin-right: 10px;
						border-radius: 5px;
						&:nth-child(4n){
							margin-right: 0px;
						}
					}
					@media (max-width: 783px) and (min-width: 600px){
						margin-right: 5px;
						border-radius: 20px;
					}
					@media (min-width: 600px) {
						max-width: 175px;
					}
					@media (max-width: 783px) {
						border-radius: 20px;
					}
					/deep/ .el-radio__label{
						width: auto;
					}
					/deep/ .select-lable .el-radio {
					   width: 100%;
					   display: flex;
					   align-items: center;
					   justify-content: center;
					}
					
					.el-checkbox,	
					.el-radio {
						margin-bottom: 6px;
					   margin-top: 10px;
						justify-content: center;
						align-items: center;
					}
			 }
			 .have-img{
				background: #ffffff;
				border: 1px #dddddd solid;
				box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149);
			 }
			 .active-img{
				background: #ffffff;
			 	border: 1px solid #666666;
				box-shadow: 0 1px 2px 0 rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149);
			 }
			 
          .nothing-img {
					box-sizing: border-box;
					padding-left: 5px;
					width: 100%;
					display: flex;
					align-items: center;
					/deep/ .el-checkbox__label,
					/deep/ .el-radio {
					  margin-right: 10px;
					}
					.select-lable {
					  margin-top: 0;
					}
          }
			::v-deep .el-select-dropdown__item{
				word-wrap: break-word;
				word-break: keep-all;  
			}
        }
        .check {
			  ::v-deep .el-checkbox{
				  margin-left: 7px;
				  display: flex;
				  justify-content: flex-start;
				  align-items: center;
				  .el-checkbox__label{
					  display: flex;
					  justify-content: flex-start;
					  align-items: center;
					  line-height: 1.3;
				  }
			  }
          .el-checkbox-group {
            width: 100%;
            display: flex;
				justify-content: flex-start;
            flex-wrap: wrap;
				@media (max-width: 600px) {
				  justify-content: space-between;
				}
          }
        }
        .error {
          display: flex;
          align-items: center;
          padding-top: 10px;
			 font-size: 12px;
          color: red;
          i {
            font-size: 18px;
          }
          span {
            margin-left: 5px;
            margin-top: 3.5px;
				line-height: 1;
          }
        }
			.empty {
				float: right;
				font-size: 13px;
				padding: 5px 6px;
				margin-top: 10px;
				cursor: pointer;
				&:hover {
					background-color: rgb(241, 236, 236);
				}
			}
      }
      .intercept {
			border: 0px dashed rgba(252, 75, 6, 0.979)
			/* border: 1px dashed rgba(252, 75, 6, 0.979) */
      }
   }
		.is_star_rating_box{
			/deep/ .is_star_rating{
				color: #FC7114;
				font-size: 20px;
			}
			/* /deep/ .el-radio__label {
			   color: #FC7114;
			} */
		}
		
		/* 修改el-radio选择颜色 */
		/deep/ .el-radio .el-radio__inner {
		  border-color: #808080; /* 选择框未选中时的边框颜色 */
		}
		/* 修改el-radio字体颜色 */
		/deep/ .el-radio .el-radio__label {
		  color: #808080; /* 未选中时的文字颜色 */
		}
		
		/* 修改el-radio选择颜色 */
		/deep/ .is-checked .el-radio__inner {
			border-color: #000000; /* 选择框未选中时的边框颜色 */
		   background: #000000;
		}
		/* 修改el-radio选中后的字体颜色 */
		/deep/ .is-checked .el-radio__label {
		  color: #000000; /* 选中时的文字颜色 */
		}
		/deep/ .is-checked .el-checkbox__inner{
			border-color: #000000; /* 选择框未选中时的边框颜色 */
			background: #000000;
		  color: #000000; /* 未选中时的文字颜色 */
		}
		/deep/ .is-checked .el-checkbox__label{
			color: #000000; /* 选中时的文字颜色 */
		}

		
		.submit {
			display: flex;
			margin: 0 auto;
			margin-top: 30px;
			max-width: 772px;
			@media (min-width: 600px){
				justify-content: space-around;
			}
			@media (max-width: 600px) {
				flex-direction: column-reverse;
			}
			
			/deep/.el-button--warning {
				width: 100%;
				@media (min-width: 600px) {
				  max-width: 50%;
				}
				border-radius: 40px;
				background-color: #ffffff;
				color: #000000;
				font-weight: 600;
				border-color: #ffffff;
				box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.141), 0 1px 3px 0 rgba(0,0,0,0.122);
				&:hover {
					color: #000000;
					font-weight: 600;
					background-color: #f5f5f5;
					border-color: #f5f5f5;
				}
			}
			.el-button+.el-button {
			   margin-left: 0px;
			}
			/deep/.BackBtn.el-button--warning {
				width: 100%;
				font-weight: 500;
				@media (min-width: 600px) {
				  max-width: 50%;
				}
				@media (min-width: 600px){
					margin-right: 20px;
					margin-top: 0px;
				}
				@media (max-width: 600px) {
					margin-right: 0px;
					margin-top: 20px;
				}
				
				border-radius: 40px;
				background-color: #000000;
				color: #ffffff;
				border-color: #ffffff;
				box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.141), 0 1px 3px 0 rgba(0,0,0,0.122);
				&:hover {
					color: #000000;
					font-weight: 600;
					background-color: #f5f5f5;
					border-color: #f5f5f5;
				}
			}
			
			/deep/.el-button--success {
				background-color: #ffffff;
				border-color: #ffffff;
				color: #000000;
				box-shadow: 0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.141), 0 1px 3px 0 rgba(0,0,0,0.122);
				&:hover {
					background-color: #f5f5f5;
					border-color: #f5f5f5;
				}
			}
		}
	}
}
</style>
