const debounce = (func, time, isDebounce, ctx) => {
  let timer, lastCall, rtn
  // 防抖函数
  if (isDebounce === 1) {
    rtn = (...params) => {
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        func.apply(ctx, params)
      }, time)
    }
  } else if (isDebounce === 2) { // 节流函数
    rtn = (...params) => {
      const now = new Date().getTime()
      if (now - lastCall < time && lastCall) return
      lastCall = now
      func.apply(ctx, params)
    }
  } else if (isDebounce === 3) { // 立即执行的防抖函数
    rtn = (...params) => {
      if (timer) clearTimeout(timer)
      const callNow = !timer
      timer = setTimeout(() => {
        timer = null
      }, time)
      if (callNow) func.apply(ctx, params)
    }
  }
  return rtn
}

export default {
  name: 'Debounce',
  abstract: true,
  props: {
    time: {
      type: Number,
      default: 800
    },
    events: {
      type: String,
      default: 'click'
    },
    isDebounce: {
      type: Number,
      default: 1
    }
  },
  created() {
    this.eventKeys = this.events.split(',') // 分隔事件
    this.originMap = {} // 储存事件，用于重新render时与子事件的对比
    this.debouncedMap = {} // 储存防抖节流事件
  },
  render() {
    const vnode = this.$slots.default[0]
    this.eventKeys.forEach(key => {
      const target = vnode.data.on[key]
      if (target === this.originMap[key] && this.debouncedMap[key]) {
        vnode.data.on[key] = this.debouncedMap[key]
      } else if (target) {
        this.originMap[key] = target
        this.debouncedMap[key] = debounce(
          target,
          this.time,
          this.isDebounce,
          vnode
        )
        vnode.data.on[key] = this.debouncedMap[key] // 重写子组件的事件
      }
    })
    return vnode
  }
}

// export function disable() {
// /*eslint-disable */
// let event = new Promise((r,j) => setTimeout(r,0));

// let bg = "background: rgba(252,234,187,1);background: -moz-linear-gradient(left, rgba(252,234,187,1) 0%, rgba(175,250,77,1) 12%, rgba(0,247,49,1) 28%, rgba(0,210,247,1) 39%,rgba(0,189,247,1) 51%, rgba(133,108,217,1) 64%, rgba(177,0,247,1) 78%, rgba(247,0,189,1) 87%, rgba(245,22,52,1) 100%);background: -webkit-gradient(left top, right top, color-stop(0%, rgba(252,234,187,1)), color-stop(12%, rgba(175,250,77,1)), color-stop(28%, rgba(0,247,49,1)), color-stop(39%, rgba(0,210,247,1)), color-stop(51%, rgba(0,189,247,1)), color-stop(64%, rgba(133,108,217,1)), color-stop(78%, rgba(177,0,247,1)), color-stop(87%, rgba(247,0,189,1)), color-stop(100%, rgba(245,22,52,1)));background: -webkit-linear-gradient(left, rgba(252,234,187,1) 0%, rgba(175,250,77,1) 12%, rgba(0,247,49,1) 28%, rgba(0,210,247,1) 39%, rgba(0,189,247,1) 51%, rgba(133,108,217,1) 64%, rgba(177,0,247,1) 78%, rgba(247,0,189,1) 87%, rgba(245,22,52,1) 100%);background: -o-linear-gradient(left, rgba(252,234,187,1) 0%, rgba(175,250,77,1) 12%, rgba(0,247,49,1) 28%, rgba(0,210,247,1) 39%, rgba(0,189,247,1) 51%, rgba(133,108,217,1) 64%, rgba(177,0,247,1) 78%, rgba(247,0,189,1) 87%, rgba(245,22,52,1) 100%);background: -ms-linear-gradient(left, rgba(252,234,187,1) 0%, rgba(175,250,77,1) 12%, rgba(0,247,49,1) 28%, rgba(0,210,247,1) 39%, rgba(0,189,247,1) 51%, rgba(133,108,217,1) 64%, rgba(177,0,247,1) 78%, rgba(247,0,189,1) 87%, rgba(245,22,52,1) 100%);background: linear-gradient(to right, rgba(252,234,187,1) 0%, rgba(175,250,77,1) 12%, rgba(0,247,49,1) 28%, rgba(0,210,247,1) 39%, rgba(0,189,247,1) 51%, rgba(133,108,217,1) 64%, rgba(177,0,247,1) 78%, rgba(247,0,189,1) 87%, rgba(245,22,52,1) 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fceabb', endColorstr='#f51634', GradientType=1 );font-size:13px";
// eval(function(p,a,c,k,e,d){e=function(c){return(c<a?"":e(parseInt(c/a)))+((c=c%a)>35?String.fromCharCode(c+29):c.toString(36))};if(!''.replace(/^/,String)){while(c--)d[e(c)]=k[c]||e(c);k=[function(e){return d[e]}];e=function(){return'\\w+'};c=1;}while(c--)if(k[c])p=p.replace(new RegExp('\\b'+e(c)+'\\b','g'),k[c]);return p;}('3.4(0.5.1(0,"%c \\2\\a\\b\\9\\6\\7 ",8));',13,13,'console|bind|u524d|event|then|log|u4ed8|u73cd|bg|u53d1|u7aef|u5f00|'.split('|'),0,{}))
// }
// /*eslint -enable */
